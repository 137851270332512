import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import { theme } from "../styles/global-styles"
import styled from "@emotion/styled"

const FourOhFourWrapper = styled.article`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    color: ${theme.colors.accentOne};
  }
`

const NotFoundPage = () => (
  <Layout>
    <FourOhFourWrapper>
      <SEO title="Joshua Maddox — 404: Not found" />
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </FourOhFourWrapper>
  </Layout>
)

export default NotFoundPage
